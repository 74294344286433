/** @format */

import React, {
  createContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { apiRequest } from 'blackbird/helpers/apiRequestHelper';
import { RequestErrorHandler } from 'javascripts/helpers/request-error-handler';
import {
  expandedProjectsLocalState,
  lastActiveProjectLocalState,
  projectsAccordionSortLocalState,
} from 'javascripts/helpers/local-state';
import logger from 'javascripts/helpers/logger';
import { RequestActions } from 'javascripts/flux/actions/request';
import { rollbar } from 'javascripts/helpers/rollbar';
import { notUndefined } from 'javascripts/helpers/notUndefined';
import type { PuffinPlanName } from '../team/TeamContext';

const errorHandler = RequestErrorHandler('Projects');

export type ViewType = 'projects' | 'archive';

export type AccordionSortOption = 'a-to-z' | 'z-to-a' | 'newest' | 'oldest';

export type TeamAction =
  | 'storyboards.create'
  | 'storyboards.rename'
  | 'storyboards.move'
  | 'storyboards.delete'
  | 'projects.create'
  | 'projects.move'
  | 'projects.rename'
  | 'projects.delete'
  | 'team.view'
  | 'team.manage'
  | 'team.billing';

export interface Project {
  id: number;
  name: string;
  description: string;
  slug: string;
  short_slug: string;
  share_slug?: string;
  share_with_description: boolean;
  share_with_sub_projects: boolean;
  share_with_password: boolean;
  share_enabled: boolean;
  share_password?: string;
  share_expires_at: string | null;
  parent_id?: number | null;
  deleted_at: string | null;
  updated_at: string;
  created_at: string;
  is_admin: boolean;
  is_accessible: boolean;
  children?: Project[];
}

export interface ProjectUpdate {
  name?: string;
  description?: string;
  share_password?: string;
  share_enabled?: boolean;
  share_with_description?: boolean;
  share_with_password?: boolean;
  share_with_sub_projects?: boolean;
  share_slug?: boolean;
}

export interface StoryboardUpdate {
  password?: string;
  has_comments_enabled?: boolean;
}

export interface ProjectGroup {
  team_id: number;
  heading: string;
  admin: string;
  is_lapsed: boolean;
  plan_name: PuffinPlanName;
  quantity: number;
  logo_url: string;
  projects: Project[];
  actions: TeamAction[];
}

export interface ProjectsResponse {
  data: {
    attributes: {
      team: ProjectGroup;
      memberships: ProjectGroup[];
    };
  };
}

export interface ProjectDocument {
  id: number;
  project_id: number;
  slug: string;
  short_slug: string;
  document_name: string;
  frame_aspect_ratio: string;
  cover_image: string | null;
  password: string | null;
  created_at: string;
  updated_at: string;
  has_comments_enabled: boolean;
  denormalized_frames: string;
  public_url: string;
  sort_order: number | null;
}

export interface ArchivedProject {
  id: number;
  parent_id?: number;
  name: string;
  slug: string;
  storyboards: any[]; // You might want to define a more specific type here
  is_admin: boolean;
  is_accessible: boolean;
  deleted_at: string;
}

export interface ArchivedStoryboard {
  id: number;
  document_name: string;
  slug: string;
  deleted_at: string;
  project_name: string;
  project_id: number;
}

export interface ArchiveData {
  projects: ArchivedProject[];
  storyboards: ArchivedStoryboard[];
}

export type ProjectContents = ProjectDocument[];

export interface NewProject {
  name: string;
  parent_id?: number;
  team_id?: number;
}

export interface ArchiveItemsParams {
  projectIds?: number[];
  storyboardIds?: number[];
  useBulkSelection?: boolean;
}

export interface ProjectsContextProps {
  updateStoryboard: (
    shortSlug: string,
    update: StoryboardUpdate,
  ) => Promise<void>;
  isUpdatingStoryboard: boolean;
  restoreProject: (projectId: string | number) => Promise<void>;
  restoreStoryboard: (shortSlug: string | number) => Promise<void>;
  deleteStoryboard: (shortSlug: string | number) => Promise<void>;
  viewType: ViewType;
  setViewType: React.Dispatch<React.SetStateAction<ViewType>>;
  duplicateStoryboard: (shortSlug: string) => Promise<void>;
  isDuplicatingStoryboard: boolean;
  duplicateStoryboardError: string | null;
  projectGroups: {
    team: ProjectGroup;
    memberships: ProjectGroup[];
  } | null;
  setProjectGroups: React.Dispatch<
    React.SetStateAction<ProjectsContextProps['projectGroups']>
  >;
  activeProject: Project | null;
  setActiveProject: React.Dispatch<React.SetStateAction<Project | null>>;
  activeGroup: ProjectGroup | null;
  setActiveGroup: React.Dispatch<React.SetStateAction<ProjectGroup | null>>;
  projectContents: ProjectContents | null;
  isLoading: boolean;
  isLoadingProjectContents: boolean; // New property
  isLoaded: boolean;
  error: string | null;
  fetchProjects: () => Promise<void>;
  moveItems: (
    newParentId?: number | null,
    projectsToMove?: number[],
    storyboardsToMove?: number[],
  ) => Promise<void>;
  isBulkMoveModalOpen: boolean;
  setIsBulkMoveModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isNewProjectModalOpen: boolean;
  setIsNewProjectModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isCreatingProject: boolean;
  createProjectError: string | null;
  createProject: (project: NewProject) => Promise<void>;
  newProjectParent: Project | null;
  setNewProjectParent: React.Dispatch<React.SetStateAction<Project | null>>;
  newProjectGroup: ProjectGroup | null;
  setNewProjectGroup: React.Dispatch<React.SetStateAction<ProjectGroup | null>>;
  createNestedProjects: (projects: Project[]) => Project[];
  flattenProjects: (projects: Project[]) => Project[];
  expandedProjectIds: Set<number>;
  disabledMoveTargetParentIds: Set<number>;
  setDisabledMoveTargetParentIds: React.Dispatch<
    React.SetStateAction<Set<number>>
  >;
  disabledMoveTargetProjectIds: Set<number>;
  setDisabledMoveTargetProjectIds: React.Dispatch<
    React.SetStateAction<Set<number>>
  >;
  isolatedExpandedProjectIds: Set<number>;
  toggleProjectExpansion: (
    projectId: number,
    isolateState?: boolean,
    force?: boolean,
  ) => void;
  expandProjectAndParents: (projectId: number) => void;
  updateProject: (projectId: number, update: ProjectUpdate) => Promise<void>;
  isUpdatingProject: boolean;
  updateProjectError: string | null;
  selectedStoryboards: number[];
  setSelectedStoryboards: React.Dispatch<React.SetStateAction<number[]>>;
  toggleStoryboardSelection: (id: number) => void;
  selectedProjects: number[];
  setSelectedProjects: React.Dispatch<React.SetStateAction<number[]>>;
  toggleProjectSelection: (id: number) => void;
  archiveItems: (params: ArchiveItemsParams) => Promise<void>;
  isArchiving: boolean;
  selectedProject: Project | null;
  setSelectedProject: React.Dispatch<React.SetStateAction<Project | null>>;
  singleProjectMoveModal: (project: Project) => void;
  isSingleProjectMoveModalOpen: boolean;
  setIsSingleProjectMoveModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  sortOption: AccordionSortOption;
  setSortOption: React.Dispatch<React.SetStateAction<AccordionSortOption>>;
  isShareModalOpen: boolean;
  setIsShareModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isSingleStoryboardMove: boolean;
  setIsSingleStoryboardMove: React.Dispatch<React.SetStateAction<boolean>>;
  fetchArchive: () => Promise<void>;
  archiveData: ArchiveData | null;
  isLoadingArchive: boolean;
  archiveError: string | null;
}

const defaultValues: ProjectsContextProps = {
  updateStoryboard: async () => {},
  isUpdatingStoryboard: false,
  restoreStoryboard: async () => {},
  deleteStoryboard: async () => {},
  restoreProject: async () => {},
  viewType: 'projects',
  setViewType: () => {},
  isShareModalOpen: false,
  setIsShareModalOpen: () => {},
  sortOption: 'newest',
  setSortOption: () => {},
  isLoadingProjectContents: false,
  updateProject: async () => {},
  moveItems: async () => {},
  isUpdatingProject: false,
  updateProjectError: null,
  projectGroups: null,
  setProjectGroups: () => {},
  activeProject: null,
  setActiveProject: () => {},
  activeGroup: null,
  setActiveGroup: () => {},
  projectContents: null,
  isLoading: false,
  isLoaded: false,
  error: null,
  fetchProjects: async () => {},
  fetchArchive: async () => {},
  isLoadingArchive: false,
  archiveData: null,
  isBulkMoveModalOpen: false,
  setIsBulkMoveModalOpen: () => {},
  isNewProjectModalOpen: false,
  setIsNewProjectModalOpen: () => {},
  isCreatingProject: false,
  createProjectError: null,
  createProject: async () => {},
  newProjectParent: null,
  setNewProjectParent: () => {},
  newProjectGroup: null,
  setNewProjectGroup: () => {},
  createNestedProjects: () => [],
  flattenProjects: () => [],
  expandedProjectIds: new Set<number>(),
  disabledMoveTargetProjectIds: new Set<number>(),
  setDisabledMoveTargetProjectIds: () => new Set(),
  disabledMoveTargetParentIds: new Set<number>(),
  setDisabledMoveTargetParentIds: () => new Set(),
  isolatedExpandedProjectIds: new Set<number>(),
  toggleProjectExpansion: () => {},
  expandProjectAndParents: () => {},
  selectedStoryboards: [],
  setSelectedStoryboards: () => {},
  toggleStoryboardSelection: () => {},
  selectedProjects: [],
  setSelectedProjects: () => {},
  toggleProjectSelection: () => {},
  archiveItems: async () => {},
  isArchiving: false,
  archiveError: null,
  selectedProject: null,
  setSelectedProject: () => {},
  singleProjectMoveModal: () => {},
  isSingleProjectMoveModalOpen: false,
  setIsSingleProjectMoveModalOpen: () => {},
  isSingleStoryboardMove: false,
  setIsSingleStoryboardMove: () => {},
  duplicateStoryboard: async () => {},
  isDuplicatingStoryboard: false,
  duplicateStoryboardError: null,
};

export const ProjectsContext =
  createContext<ProjectsContextProps>(defaultValues);

interface ProjectsProviderProps {
  children: React.ReactNode;
  project_id?: string;
  updateUrlOnLoad?: boolean;
}

export const ProjectsProvider: React.FC<ProjectsProviderProps> = ({
  children,
  project_id,
  updateUrlOnLoad = false,
}) => {
  const [isUpdatingStoryboard, setIsUpdatingStoryboard] = useState(false);
  const [viewType, setViewType] = useState<ViewType>('projects');
  const [archiveData, setArchiveData] = useState<ArchiveData | null>(null);
  const [isLoadingArchive, setIsLoadingArchive] = useState(false);
  const [isDuplicatingStoryboard, setIsDuplicatingStoryboard] = useState(false);
  const [duplicateStoryboardError, setDuplicateStoryboardError] = useState<
    string | null
  >(null);
  const [isSingleStoryboardMove, setIsSingleStoryboardMove] = useState(false);
  const [loadedProjectId, setLoadedProjectId] = useState<number | null>(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [sortOption, setSortOption] = useState<AccordionSortOption>(
    () => projectsAccordionSortLocalState.getValue() || 'newest',
  );
  const [disabledMoveTargetProjectIds, setDisabledMoveTargetProjectIds] =
    useState<Set<number>>(new Set());
  const [disabledMoveTargetParentIds, setDisabledMoveTargetParentIds] =
    useState<Set<number>>(new Set());

  const [isLoadingProjectContents, setIsLoadingProjectContents] =
    useState(false);
  const [selectedStoryboards, setSelectedStoryboards] = useState<number[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<number[]>([]);
  const [isUpdatingProject, setIsUpdatingProject] = useState(false);
  const [updateProjectError, setUpdateProjectError] = useState<string | null>(
    null,
  );
  const [isArchiving, setIsArchiving] = useState(false);
  const [archiveError, setArchiveError] = useState<string | null>(null);
  const [projectGroups, setProjectGroups] =
    useState<ProjectsContextProps['projectGroups']>(null);
  const [activeProject, setActiveProject] = useState<Project | null>(null);
  const [activeGroup, setActiveGroup] = useState<ProjectGroup | null>(null);
  const [projectContents, setProjectContents] =
    useState<ProjectContents | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isNewProjectModalOpen, setIsNewProjectModalOpen] = useState(false);
  const [isBulkMoveModalOpen, setIsBulkMoveModalOpen] = useState(false);
  const [isCreatingProject, setIsCreatingProject] = useState(false);
  const [createProjectError, setCreateProjectError] = useState<string | null>(
    null,
  );
  const [newProjectParent, setNewProjectParent] = useState<Project | null>(
    null,
  );
  const [newProjectGroup, setNewProjectGroup] = useState<ProjectGroup | null>(
    null,
  );
  const [expandedProjectIds, setExpandedProjectIds] = useState<Set<number>>(
    new Set(expandedProjectsLocalState.getValue() || []),
  );
  const [isolatedExpandedProjectIds, setIsolatedExpandedProjectIds] = useState<
    Set<number>
  >(new Set(expandedProjectsLocalState.getValue() || []));
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [isSingleProjectMoveModalOpen, setIsSingleProjectMoveModalOpen] =
    useState(false);

  const handleError = (message: string, error?: Error) => {
    RequestActions.error.defer(message);
    if (error) {
      logger.error(error);
      rollbar.error(error);
    }
  };

  const singleProjectMoveModal = useCallback((project: Project) => {
    setSelectedProject(project);
    setIsSingleProjectMoveModalOpen(true);
  }, []);

  const toggleStoryboardSelection = useCallback((id: number) => {
    setSelectedStoryboards((prev) =>
      prev.includes(id)
        ? prev.filter((storyboardId) => storyboardId !== id)
        : [...prev, id],
    );
  }, []);

  const toggleProjectSelection = useCallback((id: number) => {
    setSelectedProjects((prev) =>
      prev.includes(id)
        ? prev.filter((projectId) => projectId !== id)
        : [...prev, id],
    );
  }, []);

  const sortProjects = (
    projects: Project[],
    sortOption: AccordionSortOption,
  ): Project[] => {
    const sortFn = (a: Project, b: Project): number => {
      switch (sortOption) {
        case 'a-to-z':
          return a.name.localeCompare(b.name);
        case 'z-to-a':
          return b.name.localeCompare(a.name);
        case 'newest':
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        case 'oldest':
          return (
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
          );
        default:
          return 0;
      }
    };

    return projects.sort(sortFn).map((project) => ({
      ...project,
      children: project.children
        ? sortProjects(project.children, sortOption)
        : undefined,
    }));
  };

  const createNestedProjects = useCallback(
    (projects: Project[]): Project[] => {
      const projectMap = new Map<number, Project>();
      const rootProjects: Project[] = [];

      // First pass: create map of all projects
      projects.forEach((project) => {
        projectMap.set(project.id, { ...project, children: [] });
      });

      // Second pass: build hierarchy and check for circular references
      projectMap.forEach((project) => {
        if (project.parent_id) {
          if (project.parent_id === project.id) {
            // Circular reference detected
            logger.error(
              `Circular reference detected for project id ${project.id}. Setting parent_id to null.`,
            );
            rollbar.error(
              `Circular reference detected for project id ${project.id}`,
            );
            project.parent_id = undefined;
            rootProjects.push(project);
          } else if (projectMap.has(project.parent_id)) {
            const parent = projectMap.get(project.parent_id)!;
            // Check for deep circular references
            let currentParent = parent;
            let isCircular = false;
            while (currentParent.parent_id) {
              if (currentParent.parent_id === project.id) {
                isCircular = true;
                break;
              }
              currentParent = projectMap.get(currentParent.parent_id)!;
            }
            if (isCircular) {
              logger.error(
                `Deep circular reference detected for project id ${project.id}. Setting parent_id to null.`,
              );
              rollbar.error(
                `Deep circular reference detected for project id ${project.id}`,
              );
              project.parent_id = undefined;
              rootProjects.push(project);
            } else {
              parent.children!.push(project);
            }
          } else {
            // Parent not found, treat as root
            logger.warn(
              `Parent project ${project.parent_id} not found for project ${project.id}. Treating as root.`,
            );
            rootProjects.push(project);
          }
        } else {
          rootProjects.push(project);
        }
      });

      return sortProjects(rootProjects, sortOption);
    },
    [sortOption],
  );

  // ---------------------------------------------
  // BOF – Accordion expansion logic
  // ---------------------------------------------

  const flattenProjects = useCallback((projects: Project[]): Project[] => {
    const flattened: Project[] = [];
    const seen = new Set<number>();

    const flatten = (project: Project) => {
      if (seen.has(project.id)) {
        logger.warn(
          `Duplicate project id detected: ${project.id}. Skipping to prevent infinite loop.`,
        );
        return;
      }
      seen.add(project.id);
      flattened.push(project);
      project.children?.forEach(flatten);
    };

    projects.forEach(flatten);
    return flattened;
  }, []);

  const toggleProjectExpansion = useCallback(
    (projectId: number, isolateState?: boolean, force?: boolean) => {
      const updater: React.SetStateAction<Set<number>> = (prevIds) => {
        const newIds = new Set(prevIds);
        const shouldAdd = force ?? !newIds.has(projectId);

        if (shouldAdd) {
          newIds.add(projectId);
        } else {
          newIds.delete(projectId);
        }
        return newIds;
      };

      if (isolateState) {
        // Save project ids to isolated context state
        setIsolatedExpandedProjectIds(updater);
      } else {
        // Set expanded project ids, saved to
        // local storage below
        setExpandedProjectIds(updater);
      }
    },
    [],
  );

  useEffect(() => {
    // Save expanded projects to local storage whenever it changes
    expandedProjectsLocalState.setValue(Array.from(expandedProjectIds));
    // Also set the isolated state for when we open the overlay
    setIsolatedExpandedProjectIds(expandedProjectIds);
  }, [expandedProjectIds]);

  const createExpandProjectAndParents = useCallback(
    (setExpandedIds: React.Dispatch<React.SetStateAction<Set<number>>>) =>
      (projectId: number) => {
        setExpandedIds((prevIds) => {
          const newIds = new Set(prevIds);
          if (!projectGroups) return newIds;
          const allProjects = [
            ...projectGroups.team.projects,
            ...projectGroups.memberships.reduce<Project[]>(
              (acc, group) => [...acc, ...group.projects],
              [],
            ),
          ];
          const flattenedProjects = flattenProjects(allProjects);
          let currentProjectId: number | undefined | null = projectId;
          while (currentProjectId !== undefined) {
            const currentProject = flattenedProjects.find(
              (p) => p.id === currentProjectId,
            );
            if (currentProject && currentProject.id !== activeProject?.id) {
              newIds.add(currentProject.id);
            }
            currentProjectId = currentProject?.parent_id;
          }
          return newIds;
        });
      },
    [projectGroups, flattenProjects, activeProject],
  );

  // Sidebar version
  const expandProjectAndParents = useCallback(
    createExpandProjectAndParents(setExpandedProjectIds),
    [createExpandProjectAndParents, setExpandedProjectIds],
  );

  // Isolated/overlay version
  const expandIsolatedProjectAndParents = useCallback(
    createExpandProjectAndParents(setIsolatedExpandedProjectIds),
    [createExpandProjectAndParents, setIsolatedExpandedProjectIds],
  );

  // Set the active project to local state
  const setActiveProjectAndSave = useCallback(
    (project: Project | null) => {
      if (project && (!activeProject || project.id !== activeProject.id)) {
        setActiveProject(project);
        lastActiveProjectLocalState.setValue(project.id);
      }
    },
    [activeProject],
  );

  const canUpdateUrlAndTitle = useMemo(() => {
    const currentPath = window.location.pathname;
    return (
      updateUrlOnLoad &&
      viewType !== 'archive' &&
      currentPath !== '/pricing' &&
      !currentPath.startsWith('/checkout') &&
      !currentPath.startsWith('/welcome')
    );
  }, [updateUrlOnLoad, viewType]);

  // fetch project contents when active project changes
  useEffect(() => {
    if (viewType === 'projects') {
      if (activeProject) {
        if (canUpdateUrlAndTitle) {
          window.history.pushState({}, '', `/p/${activeProject.slug}`);
        }
        if (!loadedProjectId || loadedProjectId !== activeProject.id) {
          fetchProjectContents(activeProject.id);
        }
        expandProjectAndParents(activeProject.id);
      }
    }
  }, [activeProject, loadedProjectId, canUpdateUrlAndTitle, viewType]);

  // Expand isolated accordion
  useEffect(() => {
    if (newProjectParent) {
      expandIsolatedProjectAndParents(newProjectParent.id);
    }
  }, [newProjectParent, expandIsolatedProjectAndParents]);

  // ---------------------------------------------
  // EOF – Accordion expansion logic
  // ---------------------------------------------

  // ---------------------------------------------
  // BOF – Disabled move targets logic
  // ---------------------------------------------

  // Single project
  useEffect(() => {
    if (selectedProject && isSingleProjectMoveModalOpen) {
      // Don't allow the parent to be selected
      if (selectedProject.parent_id) {
        setDisabledMoveTargetParentIds(new Set([selectedProject.parent_id]));
      }
      // Don't allow the project itself to be selected
      setDisabledMoveTargetProjectIds((prevSet) => {
        const newSet = new Set(prevSet);

        newSet.add(selectedProject.id);

        return newSet;
      });
    }
  }, [selectedProject, isSingleProjectMoveModalOpen]);

  // Add multiple projects
  useEffect(() => {
    if (selectedProjects.length > 0 && isBulkMoveModalOpen) {
      setDisabledMoveTargetProjectIds((prevSet) => {
        const newSet = new Set(prevSet);

        selectedProjects.forEach((id) => {
          newSet.add(id);
        });
        return newSet;
      });

      const parentSet = new Set<number>();
      if (activeGroup) {
        selectedProjects.forEach((id) => {
          const selectedProjectParent = activeGroup.projects.find(
            (p) => p.id === id,
          );
          if (selectedProjectParent && selectedProjectParent.parent_id) {
            parentSet.add(selectedProjectParent.parent_id);
          }
        });
      }

      setDisabledMoveTargetParentIds(parentSet);
    }
  }, [selectedProjects, isBulkMoveModalOpen, activeGroup]);

  // Prevent storyboards from being moved to their
  // current project
  useEffect(() => {
    if (
      selectedStoryboards.length > 0 &&
      isBulkMoveModalOpen &&
      projectContents
    ) {
      selectedStoryboards.map((sid) => {
        const storyboardProject = projectContents.find((s) => s.id === sid);
        if (storyboardProject && storyboardProject.project_id) {
          setDisabledMoveTargetParentIds((prevSet) => {
            const newSet = new Set(prevSet);
            newSet.add(storyboardProject.project_id);
            return newSet;
          });
        }
      });
    }
  }, [selectedStoryboards, isBulkMoveModalOpen, projectContents]);

  // Prevent new project parent from being selected
  // if it's in the disabled IDs
  useEffect(() => {
    if (
      newProjectParent &&
      (disabledMoveTargetProjectIds.has(newProjectParent.id) ||
        disabledMoveTargetParentIds.has(newProjectParent.id)) &&
      (isBulkMoveModalOpen || isSingleProjectMoveModalOpen)
    ) {
      setNewProjectParent(null);
    }
  }, [
    newProjectParent,
    disabledMoveTargetParentIds,
    disabledMoveTargetProjectIds,
    isBulkMoveModalOpen,
    isSingleProjectMoveModalOpen,
  ]);

  // ---------------------------------------------
  // EOF – Disabled move targets logic
  // ---------------------------------------------

  const moveItems = useCallback(
    async (
      newParentId?: number | null,
      projectsToMove?: number[],
      storyboardsToMove?: number[],
    ) => {
      const parentId = notUndefined(newParentId)
        ? newParentId
        : newProjectParent?.id;
      const projectIds = [
        ...selectedProjects,
        selectedProject?.id,
        ...(projectsToMove ?? []),
      ].filter(notUndefined);
      const storyboardIds = [
        ...selectedStoryboards,
        ...(storyboardsToMove ?? []),
      ].filter(notUndefined);

      try {
        // Move projects (handles both bulk and single project moves)
        if (projectIds.length > 0) {
          if (projectIds.length === 0) {
            logger.error('No projects selected for move operation');
            return;
          }

          const moveProjectsRequest = await apiRequest({
            path: 'dashboard/projects/move',
            method: 'post',
            payload: {
              project_ids: projectIds,
              new_parent_id: parentId,
            },
          });

          if (!moveProjectsRequest.ok) {
            throw new Error('Failed to move projects');
          }
        }

        // Move storyboards (only for bulk move)
        if (storyboardIds.length > 0) {
          const moveStoryboardsRequest = await apiRequest({
            path: 'dashboard/storyboards/move',
            method: 'post',
            payload: {
              storyboard_ids: storyboardIds,
              new_project_id: parentId,
            },
          });

          if (!moveStoryboardsRequest.ok) {
            throw new Error('Failed to move storyboards');
          }
        }

        // Update the state after successful move
        setProjectGroups((prevGroups) => {
          if (!prevGroups) return prevGroups;

          const updateProjects = (projects: Project[]): Project[] => {
            return projects.map((project) => {
              if (
                projectIds.includes(project.id) ||
                project.id === selectedProject?.id
              ) {
                return { ...project, parent_id: parentId };
              }
              if (project.children) {
                return {
                  ...project,
                  children: updateProjects(project.children),
                };
              }
              return project;
            });
          };

          const newGroups = {
            team: {
              ...prevGroups.team,
              projects: updateProjects(prevGroups.team.projects),
            },
            memberships: prevGroups.memberships.map((group) => ({
              ...group,
              projects: updateProjects(group.projects),
            })),
          };

          return newGroups;
        });

        // Remove moved storyboards from the active project
        if (activeProject) {
          setProjectContents((prevContents) =>
            prevContents
              ? prevContents.filter(
                  (storyboard) => !storyboardIds.includes(storyboard.id),
                )
              : null,
          );
        }

        // Clear selections
        setSelectedProjects([]);
        setSelectedStoryboards([]);
        setSelectedProject(null);
        setIsSingleStoryboardMove(false);

        // Close the move modals
        setIsBulkMoveModalOpen(false);
        setIsSingleProjectMoveModalOpen(false);

        // Reset disabled move targets
        setDisabledMoveTargetProjectIds(new Set());
        setDisabledMoveTargetParentIds(new Set());

        await fetchProjects(activeProject?.id);
        RequestActions.success.defer('Items moved');
      } catch (error) {
        handleError('Error moving items', error);
      }
    },
    [
      newProjectParent,
      selectedStoryboards,
      selectedProjects,
      selectedProject,
      activeProject,
    ],
  );

  const findProjectById = useCallback(
    (
      groups: ProjectsContextProps['projectGroups'],
      id: number,
    ): Project | null => {
      if (!groups) return null;

      const searchInProjects = (projects: Project[]): Project | null => {
        for (const project of projects) {
          if (project.id === id) return project;
          if (project.children) {
            const found = searchInProjects(project.children);
            if (found) return found;
          }
        }
        return null;
      };

      // Search in team projects
      const teamProject = searchInProjects(groups.team.projects);
      if (teamProject) return teamProject;

      // Search in membership projects
      for (const membership of groups.memberships) {
        const membershipProject = searchInProjects(membership.projects);
        if (membershipProject) return membershipProject;
      }

      return null;
    },
    [],
  );

  const archiveItems = useCallback(
    async ({
      projectIds,
      storyboardIds,
      useBulkSelection = false,
    }: ArchiveItemsParams) => {
      const projectsToArchive = useBulkSelection
        ? selectedProjects
        : projectIds || [];
      const storyboardsToArchive = useBulkSelection
        ? selectedStoryboards
        : storyboardIds || [];

      if (projectsToArchive.length === 0 && storyboardsToArchive.length === 0) {
        logger.log(`no items to archive`);
        return;
      }

      setIsArchiving(true);
      setArchiveError(null);

      try {
        // Archive projects
        if (projectsToArchive.length > 0) {
          const archiveProjectsRequest = await apiRequest({
            path: 'dashboard/projects/archive',
            method: 'post',
            payload: {
              project_ids: projectsToArchive,
            },
          });

          if (!archiveProjectsRequest.ok) {
            throw new Error('Failed to archive projects');
          }
        }

        // Archive storyboards
        if (storyboardsToArchive.length > 0) {
          const archiveStoryboardsRequest = await apiRequest({
            path: 'dashboard/storyboards/archive',
            method: 'post',
            payload: {
              storyboard_ids: storyboardsToArchive,
            },
          });

          if (!archiveStoryboardsRequest.ok) {
            throw new Error('Failed to archive storyboards');
          }
        }

        // Update the state after successful archive
        setProjectGroups((prevGroups) => {
          if (!prevGroups) return prevGroups;

          const updateProjects = (projects: Project[]): Project[] => {
            return projects.filter(
              (project) => !projectsToArchive.includes(project.id),
            );
          };

          const newGroups = {
            team: {
              ...prevGroups.team,
              projects: updateProjects(prevGroups.team.projects),
            },
            memberships: prevGroups.memberships.map((group) => ({
              ...group,
              projects: updateProjects(group.projects),
            })),
          };

          return newGroups;
        });

        // Handle single project archive
        if (projectsToArchive.length === 1) {
          const archivedProject = findProjectById(
            projectGroups,
            projectsToArchive[0],
          );
          if (archivedProject) {
            if (archivedProject.parent_id) {
              const parentProject = findProjectById(
                projectGroups,
                archivedProject.parent_id,
              );
              if (parentProject) {
                setActiveProject(parentProject);
              }
            } else {
              window.location.href = '/';
            }
          }
        }

        // Remove archived storyboards from the active project
        if (activeProject) {
          setProjectContents((prevContents) =>
            prevContents
              ? prevContents.filter(
                  (storyboard) => !storyboardsToArchive.includes(storyboard.id),
                )
              : null,
          );
        }

        // Clear selections if using bulk selection
        if (useBulkSelection) {
          setSelectedProjects([]);
          setSelectedStoryboards([]);
          setSelectedProject(null);

          // Close any open modals related to archiving
          setIsBulkMoveModalOpen(false);
          setIsSingleProjectMoveModalOpen(false);
        }

        RequestActions.success.defer('Items archived');
      } catch (error) {
        handleError('Error archiving items', error);
      } finally {
        setIsArchiving(false);
      }
    },
    [
      projectGroups,
      selectedProjects,
      selectedStoryboards,
      activeProject,
      findProjectById,
    ],
  );

  const fetchProjects = useCallback(
    async (newProjectId?: number) => {
      if (isLoading) return; // Prevent concurrent requests
      setIsLoading(true);
      setError(null);
      try {
        const request = await apiRequest({
          path: 'dashboard/projects.json',
          method: 'get',
        });
        if (!request.ok) {
          throw new Error('Failed to fetch projects');
        }
        const response: ProjectsResponse = await request.json();

        setProjectGroups(response.data.attributes);
        setIsLoaded(true);

        const allGroups = [
          response.data.attributes.team,
          ...response.data.attributes.memberships,
        ];

        let foundProject: Project | null = null;
        let foundGroup: ProjectGroup | null = null;

        if (newProjectId) {
          for (const group of allGroups) {
            const project = group.projects.find((p) => p.id === newProjectId);
            if (project) {
              foundProject = project;
              foundGroup = group;
              break;
            }
          }
        } else if (project_id) {
          for (const group of allGroups) {
            const project = group.projects.find((p) => p.slug === project_id);
            if (project) {
              foundProject = project;
              foundGroup = group;
              break;
            }
          }
        } else {
          const lastActiveProjectId = lastActiveProjectLocalState.getValue();
          if (lastActiveProjectId) {
            for (const group of allGroups) {
              const project = group.projects.find(
                (p) => p.id === lastActiveProjectId,
              );
              if (project) {
                foundProject = project;
                foundGroup = group;
                break;
              }
            }
          }
        }

        if (!foundProject) {
          for (const group of allGroups) {
            if (group.projects.length > 0) {
              foundProject = group.projects[0];
              foundGroup = group;
              break;
            }
          }
        }

        setActiveProject(foundProject);
        setActiveGroup(foundGroup);

        setNewProjectParent(foundProject);
        setNewProjectGroup(foundGroup);

        if (foundGroup && canUpdateUrlAndTitle) {
          document.title = `${foundGroup.heading} • Boords`;
        }

        if (foundProject) {
          expandProjectAndParents(foundProject.id);
        }
      } catch (err) {
        handleError('Error fetching projects', err);
        errorHandler({ method: 'get' })(err);
      } finally {
        setIsLoading(false);
      }
    },
    [project_id, updateUrlOnLoad, canUpdateUrlAndTitle],
  );

  const fetchArchive = useCallback(async () => {
    setIsLoadingArchive(true);
    setArchiveError(null);

    try {
      const request = await apiRequest({
        path: `dashboard/archive/${activeGroup?.team_id}`,
        method: 'get',
      });

      if (!request.ok) {
        throw new Error('Failed to fetch archive data');
      }

      const response: ArchiveData = await request.json();
      setArchiveData(response);
    } catch (err) {
      errorHandler({ method: 'get' })(err);
      handleError('Error fetching archive data', err);
      setArchiveError('An error occurred while fetching archive data');
    } finally {
      setIsLoadingArchive(false);
    }
  }, [activeGroup]);

  const restoreProject = useCallback(
    async (projectId: number) => {
      try {
        const request = await apiRequest({
          path: `dashboard/projects/${projectId}/restore`,
          method: 'put',
        });

        if (!request.ok) {
          throw new Error('Failed to restore project');
        }

        // Re-fetch the projects to update the state
        await fetchProjects();

        // Remove the project and its children from the archive data
        setArchiveData((prevData) => {
          if (!prevData) return prevData;

          const isChildOf = (childId: number, parentId: number): boolean => {
            const project = prevData.projects.find((p) => p.id === childId);
            if (!project) return false;
            if (project.parent_id === parentId) return true;
            return project.parent_id
              ? isChildOf(project.parent_id, parentId)
              : false;
          };

          const updatedProjects = prevData.projects.filter(
            (p) => p.id !== projectId && !isChildOf(p.id, projectId),
          );

          return {
            ...prevData,
            projects: updatedProjects,
          };
        });

        RequestActions.success.defer('Project restored');
      } catch (err) {
        errorHandler({ method: 'put' })(err);
        handleError('Error restoring project', err);
      }
    },
    [fetchProjects, setArchiveData],
  );

  const restoreStoryboard = useCallback(
    async (shortSlug: string) => {
      try {
        const request = await apiRequest({
          path: `dashboard/storyboards/${shortSlug}/restore`,
          method: 'put',
        });

        if (!request.ok) {
          throw new Error('Failed to restore storyboard');
        }

        // Remove the storyboard from the archive data
        setArchiveData((prevData) => {
          if (!prevData) return prevData;
          return {
            ...prevData,
            storyboards: prevData.storyboards.filter(
              (s) => s.slug !== shortSlug,
            ),
          };
        });

        RequestActions.success.defer('Storyboard restored');
      } catch (err) {
        errorHandler({ method: 'put' })(err);
        handleError('Error restoring storyboard', err);
      }
    },
    [setArchiveData],
  );
  const deleteStoryboard = useCallback(
    async (shortSlug: string) => {
      try {
        const request = await apiRequest({
          path: `dashboard/storyboards/${shortSlug}`,
          method: 'delete',
        });

        if (!request.ok) {
          throw new Error('Failed to delete storyboard');
        }

        // Remove the storyboard from the archive data
        setArchiveData((prevData) => {
          if (!prevData) return prevData;
          return {
            ...prevData,
            storyboards: prevData.storyboards.filter(
              (s) => s.slug !== shortSlug,
            ),
          };
        });

        RequestActions.success.defer('Storyboard deleted');
      } catch (err) {
        errorHandler({ method: 'delete' })(err);
        handleError('Error deleting storyboard', err);
      }
    },
    [setArchiveData],
  );

  const duplicateStoryboard = useCallback(async (shortSlug: string) => {
    setIsDuplicatingStoryboard(true);
    setDuplicateStoryboardError(null);

    try {
      const request = await apiRequest({
        path: `dashboard/storyboards/${shortSlug}/duplicate`,
        method: 'post',
      });

      if (!request.ok) {
        throw new Error('Failed to duplicate storyboard');
      }

      const duplicatedStoryboard: ProjectDocument = await request.json();

      // Add the duplicated storyboard to the project contents
      setProjectContents((prevContents) =>
        prevContents
          ? [...prevContents, duplicatedStoryboard]
          : [duplicatedStoryboard],
      );

      RequestActions.success.defer('Storyboard duplicated');
    } catch (err) {
      errorHandler({ method: 'post' })(err);
      handleError('Error duplicating storyboard', err);
      setDuplicateStoryboardError(
        'An error occurred while duplicating the storyboard',
      );
    } finally {
      setIsDuplicatingStoryboard(false);
    }
  }, []);

  const fetchProjectContents = useCallback(async (id: number) => {
    try {
      setIsLoadingProjectContents(true);
      const contentsRequest = await apiRequest({
        path: `dashboard/projects/${id}`,
        method: 'get',
      });
      if (!contentsRequest.ok) {
        throw new Error('Failed to fetch project contents');
      }
      const contentsResponse: ProjectContents = await contentsRequest.json();
      setProjectContents(contentsResponse);
      setLoadedProjectId(id);
    } catch (error) {
      handleError('Error fetching project contents', error);
    } finally {
      setIsLoadingProjectContents(false);
    }
  }, []);

  const createProject = useCallback(
    async (newProject: NewProject) => {
      setIsCreatingProject(true);
      setCreateProjectError(null);

      try {
        const request = await apiRequest({
          path: 'dashboard/projects',
          method: 'post',
          payload: {
            name: newProject.name,
            parent_id: newProjectParent?.id,
            team_id: newProjectGroup?.team_id,
          },
        });
        if (!request.ok) {
          throw new Error('Failed to create project');
        }

        const createdProject: Project = await request.json();

        await fetchProjects(createdProject.id);
        await fetchProjectContents(createdProject.id);

        setIsNewProjectModalOpen(false);

        RequestActions.success.defer('Project created');
      } catch (err) {
        errorHandler({ method: 'post' })(err);
        handleError('Error creating project', err);
      } finally {
        setIsCreatingProject(false);
      }
    },
    [newProjectParent, newProjectGroup, fetchProjects],
  );

  const updateStoryboard = useCallback(
    async (shortSlug: string, update: StoryboardUpdate) => {
      setIsUpdatingStoryboard(true);

      try {
        const request = await apiRequest({
          path: `dashboard/storyboards/${shortSlug}`,
          method: 'put',
          payload: update,
        });

        if (!request.ok) {
          throw new Error('Failed to update storyboard');
        }

        const updatedStoryboard: ProjectDocument = await request.json();

        // Update the storyboard in the project contents
        setProjectContents((prevContents) =>
          prevContents
            ? prevContents.map((storyboard) =>
                storyboard.short_slug === shortSlug
                  ? { ...storyboard, ...updatedStoryboard }
                  : storyboard,
              )
            : null,
        );

        // RequestActions.success.defer('Settings updated');
      } catch (err) {
        errorHandler({ method: 'put' })(err);
        handleError('Error updating storyboard', err);
      } finally {
        setIsUpdatingStoryboard(false);
      }
    },
    [],
  );

  const updateProject = useCallback(
    async (projectId: number, update: ProjectUpdate) => {
      setIsUpdatingProject(true);
      setUpdateProjectError(null);

      try {
        const request = await apiRequest({
          path: `dashboard/projects/${projectId}`,
          method: 'put',
          payload: update,
        });

        if (!request.ok) {
          throw new Error('Failed to update project');
        }

        const updatedProject: Project = await request.json();

        // Update the project in the state
        setProjectGroups((prevGroups) => {
          if (!prevGroups) return prevGroups;

          const updateProjectInGroup = (group: ProjectGroup): ProjectGroup => ({
            ...group,
            projects: group.projects.map((p) =>
              p.id === updatedProject.id ? { ...p, ...updatedProject } : p,
            ),
          });

          return {
            team: updateProjectInGroup(prevGroups.team),
            memberships: prevGroups.memberships.map(updateProjectInGroup),
          };
        });

        // // Update active project if it's the one being updated
        setActiveProject((prevProject) =>
          prevProject?.id === updatedProject.id
            ? { ...prevProject, ...updatedProject }
            : prevProject,
        );

        // RequestActions.success.defer("Project updated")
      } catch (err) {
        errorHandler({ method: 'put' })(err);
        handleError('Error updating project', err);
        // setUpdateProjectError('An error occurred while updating the project');
      } finally {
        setIsUpdatingProject(false);
      }
    },
    [],
  );

  useEffect(() => {
    if (BoordsConfig.HasV3) {
      fetchProjects();
    }
  }, [fetchProjects]);

  const value: ProjectsContextProps = {
    fetchArchive,
    archiveData,
    isLoadingArchive,
    updateProject,
    isUpdatingStoryboard,
    isUpdatingProject,
    updateProjectError,
    projectGroups,
    setProjectGroups,
    activeProject,
    setActiveProject: setActiveProjectAndSave,
    activeGroup,
    setActiveGroup,
    projectContents,
    isLoading,
    isLoaded,
    error,
    fetchProjects,
    isNewProjectModalOpen,
    setIsNewProjectModalOpen,
    isCreatingProject,
    createProjectError,
    createProject,
    newProjectParent,
    setNewProjectParent,
    newProjectGroup,
    setNewProjectGroup,
    createNestedProjects,
    flattenProjects,
    expandedProjectIds,
    toggleProjectExpansion,
    expandProjectAndParents,
    selectedStoryboards,
    setSelectedStoryboards,
    toggleStoryboardSelection,
    selectedProjects,
    setSelectedProjects,
    toggleProjectSelection,
    isBulkMoveModalOpen,
    setIsBulkMoveModalOpen,
    moveItems,
    archiveItems,
    isArchiving,
    archiveError,
    isolatedExpandedProjectIds,
    isLoadingProjectContents,
    selectedProject,
    setSelectedProject,
    singleProjectMoveModal,
    isSingleProjectMoveModalOpen,
    setIsSingleProjectMoveModalOpen,
    sortOption,
    setSortOption,
    disabledMoveTargetProjectIds,
    setDisabledMoveTargetProjectIds,
    disabledMoveTargetParentIds,
    setDisabledMoveTargetParentIds,
    isShareModalOpen,
    setIsShareModalOpen,
    isSingleStoryboardMove,
    setIsSingleStoryboardMove,
    duplicateStoryboard,
    isDuplicatingStoryboard,
    duplicateStoryboardError,
    viewType,
    setViewType,
    restoreProject,
    restoreStoryboard,
    deleteStoryboard,
    updateStoryboard,
  };

  return (
    <ProjectsContext.Provider value={value}>
      {children}
    </ProjectsContext.Provider>
  );
};
