/** @format */

import React, { useCallback, useRef, useState, useContext } from 'react';
import CopyIcon from 'blackbird/images/icons/copy.svg';
import { ProjectsContext, type ProjectDocument } from '../ProjectsContext';
import { RequestActions } from 'javascripts/flux/actions/request';
import Dialog from 'blackbird/components/dialog/Dialog';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import Icon from 'blackbird/components/icon/Icon';
import logger from 'javascripts/helpers/logger';
import Toggle from 'blackbird/components/toggle/Toggle';

interface StoryboardShareModalProps {
  document: ProjectDocument;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const StoryboardShareModal: React.FC<StoryboardShareModalProps> = ({
  document,
  isOpen,
  setIsOpen,
}) => {
  const { updateStoryboard, isUpdatingStoryboard } =
    useContext(ProjectsContext);

  const [isPasswordEnabled, setIsPasswordEnabled] = useState(
    !!document.password,
  );
  const [password, setPassword] = useState(document.password || '');
  const [isCommentsEnabled, setIsCommentsEnabled] = useState(
    document.has_comments_enabled,
  );

  const inputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const fullShareLink = `${window.location.origin}/s/${document.short_slug}`;

  const handleCopy = useCallback((text: string, successMessage: string) => {
    try {
      navigator.clipboard.writeText(text).then(() => {
        RequestActions.success.defer(successMessage);
      });
    } catch (err) {
      logger.error('Failed to copy:', err);
    }
  }, []);

  const handleCopyLink = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.select();
      handleCopy(fullShareLink, 'Link copied');
    }
  }, [fullShareLink, handleCopy]);

  const handleCopyPassword = useCallback(() => {
    if (passwordInputRef.current) {
      passwordInputRef.current.select();
      handleCopy(password, 'Password copied');
    }
  }, [password, handleCopy]);

  const handleTogglePassword = async (value: boolean) => {
    setIsPasswordEnabled(value);
    if (!value) {
      setPassword('');
      await updateStoryboard(document.short_slug, { password: '' });
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handlePasswordBlur = async () => {
    if (isPasswordEnabled && password !== document.password) {
      await updateStoryboard(document.short_slug, { password });
    }
  };

  const handleToggleComments = async (value: boolean) => {
    setIsCommentsEnabled(value);
    await updateStoryboard(document.short_slug, {
      has_comments_enabled: value,
    });
  };

  return (
    <Dialog
      isDark
      hideActions
      size="sm"
      title={`Share link to storyboard`}
      subtitle={`${document.document_name}`}
      isOpen={isOpen}
      onCloseBtnClick={handleCloseModal}
      onEscapeOrOutsideClick={handleCloseModal}
    >
      <div className="relative pb-4">
        <div className="mb-4">
          <TextInput
            inputSize="md"
            ref={inputRef}
            value={fullShareLink}
            className="bg-surface text-type-disabled"
            onClick={handleCopyLink}
            readOnly
            rightComponent={
              <Icon onClick={handleCopyLink} icon={<CopyIcon />} />
            }
          />
          <div className="mt-8 mb-4 text-xs font-semibold tracking-wider uppercase text-type-subdued">{`Link Settings`}</div>
          <div className="space-y-3">
            <div className="flex items-center justify-between">
              <span>{`Enable comments`}</span>
              <Toggle
                value={isCommentsEnabled}
                onChange={handleToggleComments}
                disabled={isUpdatingStoryboard}
              />
            </div>
            <div className="flex items-center justify-between">
              <span>{`Password protection`}</span>
              <Toggle
                value={isPasswordEnabled}
                onChange={handleTogglePassword}
                disabled={isUpdatingStoryboard}
              />
            </div>
            {isPasswordEnabled && (
              <TextInput
                inputSize="md"
                ref={passwordInputRef}
                value={password}
                onChange={handlePasswordChange}
                onBlur={handlePasswordBlur}
                placeholder="Enter password"
                disabled={isUpdatingStoryboard}
                rightComponent={
                  password.length > 1 ? (
                    <Icon onClick={handleCopyPassword} icon={<CopyIcon />} />
                  ) : null
                }
              />
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};
